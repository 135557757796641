export enum ActionType {
  PROFILE = 'PROFILE',
  LOGIN = 'LOGIN',
  SHOW_LOADER='SHOW_LOADER',
  LIST_CAMPAIGNS='LIST_CAMPAIGNS',
  LIST_TEMPLATES='LIST_TEMPLATES',
  LIST_NOTICES='LIST_NOTICES',
  GET_CAMPAIGN='GET_CAMPAIGN',
  ADD_CAMPAIGN='ADD_CAMPAIGN',
  UPDATE_CAMPAIGN='UPDATE_CAMPAIGN',
  GET_NOTICE='GET_NOTICE',
  UPDATE_NOTICE='UPDATE_NOTICE',
  GET_TEMPLATE='GET_TEMPLATE',
  UPDATE_TEMPLATE='UPDATE_TEMPLATE',
  LIST_CONTACTS='LIST_CONTACTS',
  GET_CONTACT='GET_CONTACT',
  UPDATE_CONTACT='UPDATE_CONTACT',
  LATEST_NOTICES='LATEST_NOTICES',
  LIST_REMINDERS='LIST_REMINDERS',
  NOTICE_REPORT='NOTICE_REPORT',
  LATEST_CAMPAIGNS='LATEST_CAMPAIGNS',
  FORGOT_PASSWORD='FORGOT_PASSWORD',
  LIST_FOLDERS='LIST_FOLDERS',
  RECENT_TEMPLATES='RECENT_TEMPLATES',
  CREATE_TEMPLATE='CREATE_TEMPLATE',
  LIST_ATTACHMENTS='LIST_ATTACHMENTS',
  CHANGE_PASSWORD='CHANGE_PASSWORD',
  LIST_SUBFOLDERS='LIST_SUBFOLDERS',
  GET_FOLDER='GET_FOLDER',
  CREATE_FOLDER='CREATE_FOLDER',
  GET_SUBFOLDER='GET_SUBFOLDER',
  CREATE_SUBFOLDER='CREATE_SUBFOLDER',
  CREATE_CONTACT='CREATE_CONTACT',
  UPLOAD_CONTACT='UPLOAD_CONTACT',
  ADD_COMMENT='ADD_COMMENT',
  SEND_CAMPAIGN='SEND_CAMPAIGN',
  SEND_TEST_TEMPLATE='SEND_TEST_TEMPLATE',
  UPLOAD_ATTACHMENT='UPLOAD_ATTACHMENT',
  DELETE_TEMPLATE='DELETE_TEMPLATE',
  DELETE_FOLDER='DELETE_FOLDER',
  DELETE_SUBFOLDER='DELETE_SUBFOLDER',
  DELETE_ATTACHMENT='DELETE_ATTACHMENT',
  NEW_CAMPAIGN='NEW_CAMPAIGN',
  SEND_REMINDER='SEND_REMINDER',
  SEND_REMINDER_PREVIEW='SEND_REMINDER_PREVIEW',
  DELETE_TEMPLATE_QUESTION='DELETE_TEMPLATE_QUESTION',
  DELETE_CONTACT='DELETE_CONTACT',
  GET_CAMPAIGN_SENT_REPORT='GET_CAMPAIGN_SENT_REPORT',
  GET_CAMPAIGN_OPEN_REPORT='GET_CAMPAIGN_OPEN_REPORT',
  UPDATE_PROFILE='UPDATE_PROFILE',
  SEND_BULK_REMINDER='SEND_BULK_REMINDER'
}

export enum SnackbarActionType {
  SNACKBAR_SUCCESS = 'SNACKBAR_SUCCESS',
  SNACKBAR_FAILURE = 'SNACKBAR_FAILURE',
  SNACKBAR_CLEAR = 'SNACKBAR_CLEAR',
}
